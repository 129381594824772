<router>
{
    name: 'Dependencies',
}
</router>

<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols='8')
            DevopsDependencies
    
</template>

<script>
import DevopsDependencies from '@/components/devops/dependencies';
export default {
    meta: {
        role: 'DevopsDependencies'
    },
    components: {
        DevopsDependencies
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped></style>
